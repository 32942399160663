<template>
    <div id="app">
      <Nav />
      <div class="pageMain">
        <img class="league-img" src="@/assets/league/banner.png" />
        <!-- 头部 -->
        <div class="league-top">
          <div class="league-headline">
            <div class="league-top-left"></div>
            <div class="league-top-txt">苏州市民族管弦乐<br />艺术团队联合会</div>
          </div>
          <div class="league-txt mt-10">
            苏州市民族管弦乐艺术团队联合会是由苏州民族管弦乐团等单位发起、苏州市文化和民政部门批准，为苏州民族器乐艺术作曲、理论、指挥、教育、演奏等人才的培养平台和非职业民族器乐爱好者及民乐社团的传播交流平台，由中国广播民族乐团艺术总监兼首席指挥、苏州民族管弦乐团艺术总监兼首席指挥彭家鹏担任会长。
          </div>
          <div class="league-txt mt-35">
            联合会的成立，对于整合江南丝竹在新时代的品牌效应，进一步构建完善我市公共文化服务体系，满足人民群众在新时代的精神文化需求，具有重要的意义。
          </div>
          <div class="league-txt mt-35">
            会长彭家鹏表示，联合会的成立体现了苏州对新时代民族音乐文化事业再出发的殷切期望。自己将与理事会班子成员一起，用创新机制、用满腔热情、用不懈奋斗，共同把联合会建设好、发展好，打造新时代中国苏州民族音乐文化品牌。
          </div>
          <img class="top-img mt-10" src="@/assets/league/lea-1.png" />
        </div>
        <!-- 中间 -->
        <div class="league-content">
          <div class="league-list flex between">
            <div
              class="league-list-hz"
              v-for="(item, index) in titleList"
              :key="index"
              :class="activeIndex == item.id ? 'actives' : ''"
              @click="tabLea(item.id)"
            >
              <div class="league-list-txt">{{ item.name }}</div>
              <div class="league-list-txt">{{ item.name2 }}</div>
            </div>
          </div>
          <!-- 音乐展演 -->
          <div v-if="activeIndex == 1">
            <img class="league-concert-img mt-32" src="@/assets/league/lea-26.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-27.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-28.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-29.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-30.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-31.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-47.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-48.png" alt=""/>
          </div>
          <!-- 大运河 -->
          <div class="league-concert" v-if="activeIndex == 2">   
            <img class="league-concert-img mt-32" src="@/assets/league/lea-32.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-33.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-34.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-35.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-36.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-37.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-38.png" alt=""/>
          </div>
          <!-- 新年音乐会 -->
          <div v-if="activeIndex == 3">
            <img class="league-concert-img mt-32" src="@/assets/league/lea-39.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-40.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-41.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-42.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-43.png" alt=""/>
            <!-- <img class="league-concert-img mt-24" src="@/assets/league/lea-44.png" alt=""/>
            <img class="league-concert-img mt-24" src="@/assets/league/lea-45.png" alt=""/> -->
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </template>
  
  <script>
  import Nav from "@/components/nav.vue";
  import Footer from "@/components/footer.vue";
  export default {
    name: "App",
    components: {
      Nav,
      Footer,
    },
    data() {
      return {
        isIndex: false,
        activeIndex: 1,
        titleList: [
          {
            id: 1,
            name: "苏州民族",
            name2: "音乐展演",
          },
          {
            id: 2,
            name: "大运河",
            name2: "文化传承展示周",
          },
          {
            id: 3,
            name: "苏州市",
            name2: "新年音乐会",
          },
        ],          
      };
    },
    computed: {},
    mounted() {
      this.tabLea(1)
    },
    methods: {
      tabLea(val) {
        this.activeIndex = val;
      },
    },
  };
  </script>
  
  
  
  <style lang="scss" scoped>
  .orc-img {
    max-width: 100%;
  }
  .league-img {
    display: block;
    width: 100%;
    height: 133px;
  }
  .league-top {
    width: 100%;
    padding: 15px 16px;
    box-sizing: border-box;
    background-color: #f2f6f7;
  }
  .league-headline {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
  }
  .league-top-left {
    width: 5px;
    height: 38px;
    background-color: #b59d8f;
    margin-right: 9px;
    flex-shrink: 0;
  }
  .league-top-txt {
    color: #444444;
    font-size: 15px;
    line-height: 19px;
    font-weight: Bold;
    letter-spacing: 8px;
  }
  .league-txt {
    color: #5b5b5b;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-align: justify;
  }
  .league-content {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    background-color: #ffffff;
  }
  .league-list-hz {
    width: 100px;
    height: 42px;
    background-color: #f2f6f7;
    text-align: center;
    font-size: 14px;
    color: #666666;
    border-radius: 4px;
    font-family: Source Han Sans CN;
  }
  .league-list-hz:nth-child(2) {
    width: 110px;
  }
  .league-list-txt {
    line-height: 21px;
  }
  .actives {
    background-color: #fff2e5;
    color: #835c46;
    box-shadow: 0px 2px 0px 0px #835c46;
  }
  .league-title {
    color: #464646;
    font-size: 15px;
    margin: 22px 0 12px;
    font-family: Source Han Sans CN;
    .league-title-span {
      font-size: 12px;
      color: #b3b3b3;
    }
  }
  // 轮播图
  #homeBanner {
    border-radius: 8px;
    margin-bottom: 24px;
  }
  .league-information-ul {
    width: calc(100% + 16px);
    height: 242px;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .information-li {
    width: 244px;
    height: 242px;
    background-color: #f6f6f6;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 12px;
  }
  .information-img {
    width: 100%;
    height: 150px;
    display: block;
  }
  .information-txt1 {
    font-size: 14px;
    color: #4b4b4b;
    line-height: 20px;
    margin: 10px 10px 4px;
    // height: 40px;
  }
  .information-txt2 {
    font-size: 12px;
    color: #666666;
    line-height: 18px;
    margin: 0 10px;
  }
  .exhibition-ul {
    width: 100%;
    .exhibition-li {
      width: 100%;
      height: 190px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      margin-bottom: 8px;
      .exhibition-li-img {
        width: 100%;
        height: 100%;
      }
      .exhibition-li-hz {
        position: absolute;
        top: 46px;
        left: 16px;
        color: #fff;
        font-size: 14px;
        .exhibition-li-p {
          line-height: 18px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .league-bottom {
    width: 100%;
    padding: 0 16px 30px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #f2f6f7;
  }
  .league-dynamic-ul {
    width: 100%;
    .league-dynamic-li {
      width: 100%;
      margin-bottom: 12px;
      border-radius: 8px 8px 0 0;
      background-color: #fff;
      overflow: hidden;
      padding-bottom: 8px;
      .dynamic-li-img {
        width: 100%;
        height: 200px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .activities-ul {
    width: 100%;
    .activities-li {
      width: 100%;
      height: 70px;
      margin-bottom: 12px;
      .activities-li-img {
        width: 130px;
        height: 70px;
        border-radius: 4px;
        flex-shrink: 0;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .activities-li-txt {
    margin: 2px 0 4px 10px;
  }
  // 新年音乐会
  .league-concert-title {
    width: 100%;
    height: 43px;
    text-align: center;
    background: linear-gradient(
      0deg,
      rgba(191, 148, 123, 0.5) 0%,
      rgba(173, 124, 97, 0) 20%
    );
    color: #835c46;
    font-size: 16px;
    font-weight: bold;
  }
  .league-concert-txt {
    font-size: 14px;
    color: #4b4b4b;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: justify;
    margin: 20px 0 8px;
  }
  .league-concert-img {
    width: 100%;
    display: block;
  }
  </style>
  